<template>
  <AntRow>
    <AntCol
      :sm="{ span: 24, offset: 0 }"
      :md="{ span: 20, offset: 2 }"
      :lg="{ span: 16, offset: 4 }"
    >

      <AntRow class="ant-form-item">
        <AntCol :span="6">
          Current Bet Limit:
        </AntCol>
        <AntCol :span="18" :style="{ position: 'relative'}">
          <pre v-if="prettyPrint" class="bet-limit json-code">{{ prettifyJsonString(loginUserData.betLimit) }}</pre>
          <div v-else class="bet-limit">{{ loginUserData.betLimit }}</div>

          <AntTooltip placement="top" title="Pretty Print">
            <AntButton size="small" class="pretty-print-button" @click="onClickPrettyPrint">
              { }
            </AntButton>
          </AntTooltip>
          <!-- <AntTooltip placement="top" title="Copy to Clipboard">
            <AntButton size="small" class="copy-button" @click="onClickCopy">
              <template #icon>
                <SnippetsIcon />
              </template>
            </AntButton>
          </AntTooltip> -->
        </AntCol>
      </AntRow>

      <FormTemplate
        :defaultData="loginUserData"
        :formFields="formFields"
        :onSubmit="onSubmit"
      />

      <AntAlert
        v-if="!!alertState.message"
        :type="alertState.type"
        :message="alertState.message"
        showIcon
      />
    </AntCol>
  </AntRow>
</template>

<script>
import { ref, reactive } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'
import { isEqual } from 'lodash'

import {
  Row as AntRow,
  Col as AntCol,
  Button as AntButton,
  Tooltip as AntTooltip,
  Alert as AntAlert,
} from 'ant-design-vue'

// import SnippetsIcon from '@ant-design/icons-vue/lib/icons/SnippetsOutlined'

import { updateUser } from '@/api/tssw'
import prettifyJsonString from '@/utils/prettifyJsonString'

import { AWC_ENV_LIST } from '@/constants/index'
import { AGENT_CONFIG, UPDATE_BET_LIMIT_FORM_FIELDS } from '@/constants/tssw'

import FormTemplate from '@/components/AgentManagement/FormTemplate'

export default {
  name: 'UpdateBetLimit',
  components: {
    AntRow,
    AntCol,
    AntButton,
    AntTooltip,
    AntAlert,
    // SnippetsIcon,
    FormTemplate,
  },
  props: {
    awcEnv: String,
    loginUserData: Object,
    requestResponseData: Object,
  },
  emits: ['changeLoginUserData'],
  setup(props, { emit }) {
    const router = useRouter()
    if (!props.loginUserData.userId) router.push('/tssw/login')

    const prettyPrint = ref(false)
    const requestResponseData = reactive(props.requestResponseData)
    const alertState = reactive({})

    const onSubmit = async (formState) => {
      const { awcEnv: tsswAwcEnv, loginUserData } = props
      const [awcEnv] = tsswAwcEnv.split('_') // AWCA_PR => AWCA, AWS03_PR => AWS03

      formState.betLimit = JSON.stringify(JSON.parse(formState.betLimit))

      const requestData = {
        cert: AGENT_CONFIG[tsswAwcEnv].cert,
        agentId: AGENT_CONFIG[tsswAwcEnv].agentId,
        userId: loginUserData.userId,
        ...formState,
      }

      const URL = `${AWC_ENV_LIST[awcEnv].domain}/wallet/updateBetLimit`
      const { data: { data: awcData } } = await axios.post(`/proxy/${URL}`, requestData)

      if (awcData) {
        requestResponseData.url = URL
        requestResponseData.request = JSON.stringify(requestData)
        requestResponseData.response = JSON.stringify(awcData)

        if (awcData.status === '0000') {
          const formBetLimit = JSON.parse(formState.betLimit)
          const userBetLimit = JSON.parse(loginUserData.betLimit || '{}')

          let shouldUpdateBetlimit = false
          Object.entries(formBetLimit).forEach(([platform, platformBetLimit]) => {
            if (!isEqual(userBetLimit[platform], platformBetLimit)) {
              shouldUpdateBetlimit = true
              userBetLimit[platform] = platformBetLimit
            }
          })

          if (shouldUpdateBetlimit) {
            const { data: updateUserData, message } = await updateUser({
              userId: loginUserData.userId,
              env: tsswAwcEnv,
              betLimit: JSON.stringify(userBetLimit),
            })

            if (updateUserData) {
              alertState.type = 'success'
              alertState.message = 'Update bet limit successfully'

              emit('changeLoginUserData', updateUserData)
            } else if (message) {
              alertState.type = 'error'
              alertState.message = message
            }
          }
        } else {
          alertState.type = 'error'
          alertState.message = JSON.stringify(awcData)
        }
      }
    }

    const onClickCopy = () => navigator.clipboard.writeText(props.loginUserData.betLimit)
    const onClickPrettyPrint = () => prettyPrint.value = !prettyPrint.value

    return {
      formFields: UPDATE_BET_LIMIT_FORM_FIELDS,
      prettyPrint,
      alertState,
      onSubmit,
      onClickCopy,
      onClickPrettyPrint,
      prettifyJsonString,
    }
  },
}
</script>

<style scoped>
.bet-limit {
  min-height: 200px;
  max-height: 30vh;
  overflow: auto;
  word-break: break-word;
}

.json-code {
  font-size: 12px;
  white-space: break-spaces;
  padding-right: 0;
  width: 100%;
}

/* .copy-button {
  position: absolute;
  right: 15px;
  top: 0;
} */

.pretty-print-button {
  position: absolute;
  /* right: 40px; */
  right: 15px;
  top: 0;
  padding: 0px 5px;
}
</style>
